import React from "react";
import Debug from "debug";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
import { addItemToArray } from "./util-hooks";

const { dataSourceName } = Config();
const debug = Debug("useInvites.js");

export function useInvites(params) {
  const store = params?.store;
  const realmApp = useRealmApp();
  const [invites, setInvites] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "invites",
  });

  React.useEffect(() => {
    const pipeline = []
    if (store) {
      pipeline.push({ "$match": { "store._id": store._id } })
    }

    collection.aggregate(pipeline).then((res) => {
      debug('fetch:mongo', { res })
      setInvites(res);
      setLoading(false);
    });
  }, [store, collection]);

  const createInvite = async ({ email, kind, store }) => {
    const { _id, name } = store
    const item = {
      _partition: realmApp.currentUser.id,
      kind,
      status: 'pending',
      store: { _id, name },
      email,
      createdTs: Date.now()
    };
    try {
      await collection.insertOne(item);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn('Error: tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending duplicate request');
      }
      console.error(err);
    }

    setInvites(addItemToArray(item, invites));
  };

  const removePendingInvite = async ({email, store}) => {
    await collection.findOneAndDelete({email, status:'pending', 'store._id': store._id })
  }

  const update = async (id, doc) => {
    doc.updatedTs = Date.now()
    try {
      await collection.updateOne(
        { _id: id },
        { $set: doc }
      );
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn('Error: tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending duplicate request');
      }
      console.error(err);
    }
  };

  return {
    loading,
    invites,
    createInvite,
    removePendingInvite,
    update
  };
}
