import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function About() {

  return (
    <main>
      <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
        <Container maxWidth="sm">
          <Typography component="h4"
            variant="h6" align="center" gutterBottom>
            The platform to build your own marketplace.
          </Typography>
          <Typography variant="p" paragraph>
            SwagShops is building an e-commerce platform designed for mobile that allows collaboration from other sellers.
            Initially built to support retail clothing consignment, but actively expanding our features for more use cases.
          </Typography>
          <Typography variant="p" paragraph>
            If interested in getting early access, sign-up below             
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: "<iframe title='Terms' frameborder='0' height='480' width='100%' src='/content/mailchimp.html' />" }} />
          <Typography variant="p" paragraph>
            Some important info:
          </Typography>
          <ul>
            <li><Link to={"/about/privacy"}>Privacy Notice</Link></li>
            <li><Link to={"/about/terms"}>Terms of Use</Link></li>
          </ul>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center">

          </Stack>
        </Container>
      </Box>
    </main>
  );
}
