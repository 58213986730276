import React from "react";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
import { BSON } from "realm-web";

const { dataSourceName } = Config();
const STORAGE_KEY = "consignors";

export function useConsignors() {
  const realmApp = useRealmApp();
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "consignors",
  });

  React.useEffect(() => {
    if (sessionStorage.getItem(STORAGE_KEY)) {
      setItems(JSON.parse(sessionStorage.getItem(STORAGE_KEY)));
      setLoading(false);
      return
    }
    collection.find().then((res) => {
      console.log('useConsignors.js fetch:mongo', {res})
      setItems(res);
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(res));
      setLoading(false);
    });
  }, [collection]);

  const addConsignor = async ({name, venmo}) => {
    const item = {};
    item._id = new BSON.ObjectID()
    item._partition = realmApp.currentUser.id;
    item.storeId = 'shop-abc' // TODO: use a shopId
    item.name = name;
    item.venmo = venmo;
    // item.updated_ts = new Date().getTime();
    try {
      await collection.insertOne(item);
      items.push(item);
      setItems(items);
      sessionStorage.removeItem(STORAGE_KEY);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const updateConsignor = async (item) => {
    console.log('updateConsignor:', item)
    try {
      await collection.updateOne(
        {_id: item._id},
        { $set: item }
      );
      sessionStorage.removeItem(STORAGE_KEY);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const deleteConsignor = async (item) => {
    const updatedItems = items.filter((i) => i._id !== item._id);
    setItems(updatedItems);
    await collection.deleteOne({ _id: item._id });
  };

  return {
    loading,
    items,
    addConsignor,
    updateConsignor,
    deleteConsignor
  };
}
