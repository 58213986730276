import React from "react";
import { Container, LinearProgress } from "@mui/material";
import { useShowLoader } from "../../../hooks/util-hooks";
import { useStores } from "./useStores";
import { BSON } from "realm-web";
import { ShopCreateForm } from "./ShopCreateForm";

export function ShopCreatePage({currentUser}) {
  const { loading, stores, ...actions } = useStores();
  const showLoader = useShowLoader(loading, 200);

  const cnt = stores?.length + 1
  const id = new BSON.ObjectID()
  const store = {
    _id: id,
    id: id.toString(), // for $lookup
    admins: Array(currentUser.id),
    name: `New store #${cnt}`,
    isConsignment: false,
    domains: Array(`${id.toString().substring(0, 6)}.swag-shops.com`),
    categories: [{ name: "Other", slug: "other", cover: true }],
    options: [{ size: 'S' }, { size: 'M' }, { size: 'L' }],
    enableSelling: false,
    enableInventory: false,
    created_ts: new Date(),
    updated_ts: new Date()
  };

  return (
    <Container disableGutters={true} maxWidth="sm">
      {loading ? (showLoader ? (<LinearProgress />) : <div />) : (
        <>
          <ShopCreateForm store={store} actions={actions} currentUser={currentUser} />
        </>
      )}
    </Container>
  );
}
