import React from "react";
import { Avatar, Button, Card, CircularProgress, Divider, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import ProfileImageUpload from "./ProfileImageUpload";
import { AccountBalance, CurrencyExchange } from "@mui/icons-material";
import { MerchantAccountDialog } from "./MerchantAccountDialog";
import { getOnboardStatus } from "../../../shared/getEndpoint";


export default function ProfileForm({ currentUser, upsertUser, user, store }) {
    const [dirty, setDirty] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const resize = `image/upload/c_pad,b_lightgrey,w_70,h_70/`

    const { fullname, venmo, address, imageUrl, merchantId } = user || {};
    const [formMerchantId, setFormMerchantId] = React.useState(merchantId);
    const [sellerStatus, setSellerStatus] = React.useState({});
    const [openLoading, setOpenLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const roles = []
    // console.log('user:', store, user.sellerStores?.includes(store._id)); // need for Seller invite

    if (user?.sellerStores?.includes(store._id)) {
        roles.push('seller')
    }

    const saveProfile = async (profile) => {
        try {
            console.log('submit', profile);
            upsertUser(profile);
        } catch (err) {
            console.error({ err });
        }
    };

    function handleInput(e) {
        setDirty(true);
    };

    async function handleManagePaypal(e) {
        setOpenLoading(true);
        const _sellerStatus = await getOnboardStatus(formMerchantId);
        setOpenLoading(false);
        console.log({_sellerStatus});
        setSellerStatus(_sellerStatus);
        setOpen(true);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = currentUser?.profile?.email;
        const { venmo, fullname, street, city, postalcode } = Object.fromEntries(formData.entries());
        const profile = {
            venmo, fullname, email, address: { street, city, postalcode }
        };
        await saveProfile(profile);
        setSnackbarOpen(true);
        setDirty(false);
        currentUser.refreshCustomData(); // prevent stale customData
    }

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpen(false)}
                message="Profile saved"
            />
            <Card sx={{ p: 2 }} variant="outlined">
                <Typography component="h4" variant="h5" sx={{ mb: 2 }}>
                    Profile {roles.length ? <>({roles.join(', ')})</> : ''}
                    <span style={{ fontSize: 12, marginLeft: 6 }}>ID: {currentUser.id.substring(19, 30)}</span>
                    {imageUrl ?
                        <ProfileImageUpload upsertUser={upsertUser}>
                            <Avatar alt={fullname ? fullname[0][0] : ''} src={imageUrl.split('image/upload/').join(resize)} />
                        </ProfileImageUpload>
                        :
                        <ProfileImageUpload buttonTitle={"Upload Profile Pic"} upsertUser={upsertUser} />
                    }

                </Typography>
                <form className="swag-form" onSubmit={handleSubmit}>
                    <TextField
                        defaultValue={fullname}
                        id="input-fullname"
                        name="fullname"
                        label="Full name"
                        variant="outlined"
                        onChange={handleInput}
                    />
                    <TextField
                        defaultValue={currentUser?.profile?.email}
                        id="input-email"
                        size="small"
                        name="email"
                        label="Email"
                        variant="outlined"
                        disabled={true}
                    />
                    <TextField
                        defaultValue={address?.street}
                        size="small"
                        id="input-street"
                        name="street"
                        label="Street address"
                        variant="outlined"
                        onChange={handleInput}
                    />
                    <TextField
                        defaultValue={address?.city}
                        size="small"
                        id="input-city"
                        name="city"
                        label="City"
                        variant="outlined"
                        onChange={handleInput}
                    />
                    <TextField
                        defaultValue={address?.postalcode}
                        size="small"
                        id="input-postalcode"
                        name="postalcode"
                        label="Zip"
                        variant="outlined"
                        onChange={handleInput}
                    />
                    <Divider />
                    {user?.sellerStores?.length > 0 &&
                        <>
                            <TextField
                                id="input-venmo"
                                size="small"
                                defaultValue={venmo}
                                name="venmo"
                                label="Seller Email"
                                variant="outlined"
                                onChange={handleInput}
                            />
                            {formMerchantId ?
                                <TextField
                                    sx={{ mr: 2, flex: 1 }}
                                    value={formMerchantId}
                                    id="input-merchantId"
                                    name="merchantId"
                                    label="Seller Merchant ID"
                                    variant="outlined"
                                    disabled={true}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" color="info" onClick={handleManagePaypal} edge="end">
                                                <>{openLoading ? <CircularProgress color="info" /> : <AccountBalance />} &nbsp; Merchant Account</>
                                            </IconButton>
                                        )
                                    }}
                                /> :
                                <Button variant="contained" color="info" startIcon={<CurrencyExchange />}
                                    onClick={() => setOpen(true)}>
                                    Connect PayPal Merchant Account <Typography color={"error"}>&nbsp;</Typography>
                                </Button>
                            }
                        </>}
                    <Button disabled={!dirty} type="submit" variant="contained" color="primary">
                        Update
                    </Button>
                </form>
            </Card>
            <MerchantAccountDialog
                open={open}
                sellerStatus={sellerStatus}
                formMerchantId={formMerchantId}
                setFormMerchantId={setFormMerchantId}
                setShowDialog={setOpen}
            />
        </>
    );
}
