import React from "react";
import { useCollection } from "../../hooks/useCollection";
//import { useRealmApp } from "../../components/RealmApp";
import Config from "../../shared/Config";
const { dataSourceName } = Config();

export function useChats() {
  //const realmApp = useRealmApp();
  const [chats, setChats] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "chatMessages",
  });

  React.useEffect(() => {
    const groupStage = {
      $group: {
        _id: "$conversationId",
        message: {
          $last: "$$ROOT"
        }
      }
    }
    
    collection.aggregate([
    //   {
    //     $lookup:
    //       {
    //         from: "users",
    //         localField: "_partition",
    //         foreignField: "id",
    //         as: "seller"
    //       }
    //  },
    //  { "$match" : { "storeId" : store._id } },
    //  { "$match" : { "status" : { "$in": ["active","sold"] }} }
    { $limit: 60 },
    groupStage
    //{ $sort: { "created_ts": 1 } }
   ]).then((res) => {
    function sortMsgs(a,b) {
      return a.message.created_ts - b.message.created_ts
    }
    const sorted = res.sort(sortMsgs)
      console.log('useChats.js: mongo', {sorted})
      setChats(sorted);
      setLoading(false);
    });
  }, [collection]);

  // const insertChat = async (draftChat) => {
  //   if (draftChat.imageUrl) {
  //     draftChat._partition = realmApp.currentUser.id;
  //     try {
  //       await collection.insertOne(draftChat);
  //     } catch (err) {
  //       if (err.error.match(/^Duplicate key error/)) {
  //         console.warn(
  //           `The following error means that we tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
  //         );
  //       }
  //       console.error(err);
  //     }
  //   }
  // };

  // const updateChat = async (chat) => {
  //   console.log({chat})
  //   try {
  //     await collection.updateOne(
  //       {_id: chat._id},
  //       { $set: chat }
  //     );
  //   } catch (err) {
  //     if (err.error.match(/^Duplicate key error/)) {
  //       console.warn(`tried to insert a doc multiple times`);
  //     }
  //     console.error(err);
  //   }
  // };

  return {
    loading,
    chats,
    // insertChat,
    // updateChat
  };
}
