import { Button, Card, Container, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRealmApp } from "../RealmApp";

export function EmailConfirmPage() {
    const realmApp = useRealmApp();
    const emailInput = React.useRef();
    const noErrors = { email: null, confirm: null };
    const [error, setError] = React.useState(noErrors);
    const [done, setDone] = React.useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const token = searchParams.get('token');
    const tokenId = searchParams.get('tokenId');

    React.useEffect(() => {
        if (!token) {
            setError((p) => ({ ...p, confirm: 'notoken' }));
        }
    }, [token])

    const confirmClickHandler = async () => {
        try {
            await realmApp.emailPasswordAuth.confirmUser({ token, tokenId });
            setError((p) => ({ ...p, confirm: null }))
            setDone(true)
            navigate('/profile');
            // setShowLogin(true)
        } catch (ex) {
            console.log('err:', ex.error);
            setError((p) => ({ ...p, confirm: ex.error }));
        }
    };

    const sendEmailClickHandler = async () => {
        try {
            await realmApp.emailPasswordAuth.resendConfirmationEmail({
                email: emailInput.current.value
            });
            setError((p) => ({ ...p, email: null }))
        } catch (ex) {
            console.log('err:', ex.error);
            setError((p) => ({ ...p, email: ex.error }));
        }
    };

    return (
        <Container maxWidth="sm" className="main-container">
            {error.confirm !== "notoken" && <Card className="auth-card" variant="outlined">
                <Typography style={{ marginBottom: 5 }} variant="h6">Confirm your SwagShop account</Typography>
                <Button variant="contained" color="primary" onClick={confirmClickHandler}>
                    Confirm
                </Button>
                {Boolean(error.confirm) && <Typography color="error">{error.confirm}</Typography>}
                {done && <h3>Email confirmed! Please Login</h3>}
            </Card>}
            {Boolean(error.confirm) ?
                <Card className="auth-card" variant="outlined">

                    <Typography style={{ marginBottom: 5 }}>Resend confirmation email</Typography>
                    <TextField
                        id="input-resend-email"
                        name="resend-email"
                        label="Email"
                        variant="outlined"
                        inputRef={emailInput}
                        error={Boolean(error.email)}
                        helperText={error.email ?? ""}
                    />
                    <Button variant="contained" color="primary" onClick={sendEmailClickHandler}>
                        Send Email
                    </Button>
                </Card>
                : ''}
        </Container>
    )

}