import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { useInvites } from "../../../hooks/useInvites";
import { timeAgo } from "../../../hooks/util-hooks";
import { getItemIndex, updateValueAtIndex } from "../../../shared/helpers";

export function ProfileInvites({currentUser, user, invites, upsertUser}) {
  const [myInvites, setInvites] = useState(invites);
  const { update: updateInvite } = useInvites();

  const handleAccept = async (i) => {
    updateInvite(i._id, {status:'accepted'})

    let sellerStores = []
    if (Array.isArray(user?.sellerStores)) {
      sellerStores = user.sellerStores;
    }

    if (!sellerStores.includes(i._id)) {
      sellerStores.push(String(i.store._id));
      await upsertUser({ sellerStores })
      console.log('added sellerStore', i.store._id);
    }

    // update component state
    i.status = 'accepted'
    setInvites((oldItems) => {
      const idx = getItemIndex(oldItems, i);
      return updateValueAtIndex(oldItems, idx, () => i);
    });
    await currentUser.refreshCustomData();
    setTimeout(() => {
      window.location.reload();
    }, 300)
  }
    
    return (
        <Table sx={{ minWidth: 340 }} size="small" aria-label="invites table">
        <TableHead>
          <TableRow>
            <TableCell>Sent</TableCell>
            <TableCell>Invite</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {myInvites.map((i) => (
          <TableRow key={i._id}
             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>{timeAgo(i.createdTs)}</TableCell>
            <TableCell>Be a {i.kind} on {i.store.name}</TableCell>
            <TableCell style={{textTransform:'capitalize'}}>
              {i.status === 'pending' ? <Button variant="contained" size="small" 
              onClick={(e)=> {
                e.preventDefault()
                handleAccept(i)
              }}>Accept</Button>: 'accepted'}
              </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
    )
}