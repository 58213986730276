import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { timeAgo } from "../../hooks/util-hooks";
import ViewOrder from "../forms/ViewOrder";

export function EventsTable({ items, currentUser }) {
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="listing table">
        <TableHead>
          <TableRow>
            <TableCell sortDirection={"desc"}>Placed</TableCell>
            <TableCell align="left" variant="head">
              Item
            </TableCell>
            <TableCell>Payment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!items.length && (
            <TableRow>
              <TableCell>
                No results...
              </TableCell>
            </TableRow>
          )}
          {items.map((i) => (
            <TableRow
              hover
              tabIndex={-1}
              key={i._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => {
                if (i.event_type !== 'ORDER.CREATED') {
                  return;
                }
                setOpen(true);
                setOrder({ ...i });
              }}
            >
              <TableCell>
                {timeAgo(i.created_ts)}
              </TableCell>
              <TableCell scope="row">
                <Typography variant="caption">{i.event_type} / {new Date(i.created_ts).toLocaleString()}</Typography>
                <Typography>
                  {i.data?.orderID &&
                    <>ID:{i.data.orderID}</>
                  }
                  {i.resource?.purchase_units &&
                    <>
                     {i.resource.purchase_units[0]?.description}
                    </>
                  }
                </Typography>
                {i.resource &&
                  <Typography variant={"caption"}>
                    resource.id: {i.resource.id} {i.resource.status}
                  </Typography>
                }
                {i.item?.seller &&
                  <>
                    <Typography variant={"subtitle1"}>
                      {i.item?.title} ${i.item?.price} ({i.item?.category})
                    </Typography>
                    <Typography variant={"body2"}>
                      Seller: {i.item?.seller[0] && <>{i.item?.seller[0].fullname}</>}
                    </Typography>
                    <Typography variant={"body2"}>
                      Payer: {i.payment?.payer.name.given_name} {i.payment?.payer.name.surname} ({i.payment?.payer.email_address})
                    </Typography>
                  </>
                }
              </TableCell>
              <TableCell>
                {i.resource?.amount ?
                  <>
                    ${i.resource?.amount.value}
                  </>
                  : ""}
                {i.payment?.purchase_units &&
                  <>
                    ${i.payment?.purchase_units[0]?.amount.value} ({i.data?.paymentSource}) <br />
                  </>
                }
                {i.item?._partition === currentUser.id && (
                  <>
                    <div>Consignor: {i.item.consignor}</div>
                    <div>
                      Payer: {i.payment?.payer.name.given_name}{" "}
                      {i.payment?.payer.name.surname}
                    </div>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
        <DialogTitle>
          View Order
          <IconButton style={{ float: "right" }} onClick={() => setOpen(false)}>
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ViewOrder order={order} currentUser={currentUser}></ViewOrder>
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
}
