import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { CheckBox, CloseRounded, Error } from "@mui/icons-material";

export function MerchantAccountStatus({ open, setShowDialog, sellerStatus }) {
    // casting response
    const {legal_name, payments_receivable, primary_email_confirmed} = sellerStatus;
    const legalName = typeof legal_name === 'string' ? legal_name : 'N/A';
    const paymentsReceivable = typeof payments_receivable === 'boolean' ? payments_receivable : false;
    const emailConfirmed = typeof primary_email_confirmed === 'boolean' ? primary_email_confirmed : false;

    const handleClose = (value) => {
        setShowDialog(false);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={false}>
            <DialogTitle>Merchant account
            <IconButton style={{ float: 'right' }} onClick={() => setShowDialog(false)}>
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
                    <>
                        <Typography variant="subtitle2">Account: {legalName} (Paypal) </Typography>
                        <br />
                        <Typography variant="p">Payments receivable? {paymentsReceivable ? <CheckBox color="success" sx={{verticalAlign:"middle"}} /> : <Error color="error" />}  </Typography>
                        <br />
                        <Typography variant="p">Email confirmed? {emailConfirmed ? <CheckBox color="success" sx={{verticalAlign:"middle"}} /> : <Error color="error" />}  </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="subtitle2" style={{ margin: 1 }}>Disconnecting from your PayPal account</Typography>
                        <ul style={{ fontSize: 12 }}>
                            <li>
                                Login to your PayPal account and click on your icon in the top-right corner
                            </li>
                            <li>
                                Under &quot;Account Settings&quot; select &quot;API Access&quot;
                            </li>
                            <li>
                                Under REST API Integration, select &quot;Manage REST API apps and credentials&quot;
                            </li>
                            <li>
                                Select the app you would like to revoke and remove permissions from the platform
                            </li>
                        </ul>
                    </>
            </DialogContent>
        </Dialog>
    )
}