import { Publish } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import React from "react";

export default function PaypalSecretEdit({ store, actions }) {
    const [isFormDirty, setFormDirty] = React.useState(false);
    const noErrors = { paypalSecret: null };
    const [error] = React.useState(noErrors);

    function disableSave() {
        return !isFormDirty;
    }

    function handleInputChange () {
        setFormDirty(true);
    }

    return (
        <>
            <form               
                style={{ display: "flex", flexDirection: "column", gap: 18 }}
                onSubmit={async (e) => {
                    e.preventDefault();
                    const formData = new FormData(e.target);
                    const { paypalSecret_live, paypalSecret_sandbox } = Object.fromEntries(formData.entries());
                    store.paypalSecret = {
                        live: paypalSecret_live,
                        sandbox: paypalSecret_sandbox
                    };
                    store.updated_ts = Date.now();
                    console.log('update Secrets');
                    await actions.updateStore(store);
                    setFormDirty(false);
                }}>

                <TextField
                    sx={{ mt: 1 }}
                    id="input-paypalSecret_sandbox"
                    autoComplete="off"
                    type="text"
                    defaultValue={store.paypalSecret?.sandbox}
                    name="paypalSecret_sandbox"
                    label="Sandbox"
                    variant="outlined"
                    error={Boolean(error.paypalSecret)}
                    onChange={handleInputChange}
                    helperText={error.paypalSecret ?? ""}
                />
                <TextField
                    id="input-paypalSecret_live"
                    type="text"
                    aria-autocomplete="none"
                    autoComplete="off"
                    defaultValue={store.paypalSecret?.live}
                    name="paypalSecret_live"
                    label="Live"
                    variant="outlined"
                    error={Boolean(error.paypalSecret)}
                    onChange={handleInputChange}
                    helperText={error.paypalSecret ?? ""}
                />
                <Button
                    startIcon={<Publish />}
                    disabled={disableSave()}
                    type="submit" variant="contained" color="primary">
                    Save
                </Button>
            </form>
        </>
    );
}
