import React from "react";
import {
  Button,
  TextField,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  Card
} from "@mui/material";
import { Publish } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function ShopCreateForm({ store, actions, currentUser }) {
  const [isFormDirty, setFormDirty] = React.useState(true);
  const navigate = useNavigate()

  const admins = ['bradoyler@gmail.com']
  const isSuperAdmin = admins.includes(currentUser.profile.email);

  function disableSave() {
    // TODO: enable for store admins
    if (isSuperAdmin && isFormDirty) {
      return false;
    }
    return true;
  }

  const noErrors = {
    name: null,
    isConsignment: null,
    other: null,
  };

  const [error] = React.useState(noErrors);

  const handleInputChange = (event) => {
    setFormDirty(true);
  };

  return (
    <>
      <Card sx={{ p: 2 }} variant="outlined">
        <form className="auth-form"
          style={{ paddingTop: 6 }}
          onSubmit={async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const { name, isConsignment, categories, options } = Object.fromEntries(formData.entries());
            store.name = name;
            store.isConsignment = Boolean(isConsignment === 'true');
            if (categories) {
              store.categories = JSON.parse(categories);
            }
            if (options) {
              store.options = JSON.parse(options);
            }
            store.updated_ts = new Date();
            console.log('create:', { item: store }, { isConsignment });
            await actions.createNewStore(store);
            navigate('/shops')
          }}
        >
          <TextField
            id="input-name"
            defaultValue={store.name}
            name="name"
            label="Name"
            variant="outlined"
            error={Boolean(error.name)}
            onChange={handleInputChange}
            helperText={error.name ?? ""}
          />
          <FormControl>
            <InputLabel id="isConsignment-label">Enable Consignment?</InputLabel>
            <Select
              id="select-isConsignment"
              labelId="isConsignment-label"
              name="isConsignment"
              label="Enable Consignment?"
              defaultValue={store.isConsignment}
              onChange={handleInputChange}>
              <MenuItem key={String(true)} value={true}>True</MenuItem>
              <MenuItem key={String(false)} value={false}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="enableInventory-label">Enable Inventory?</InputLabel>
            <Select
              id="select-enableInventory"
              labelId="enableInventory-label"
              name="enableInventory"
              label="Enable Inventory?"
              defaultValue={store.enableInventory}
              onChange={handleInputChange}>
              <MenuItem key={String(true)} value={true}>True</MenuItem>
              <MenuItem key={String(false)} value={false}>False</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="input-categories"
            multiline
            rows={4}
            name="categories"
            defaultValue={JSON.stringify(store.categories)}
            label="Item Categories"
            variant="outlined"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
          <TextField
            id="input-options"
            multiline
            rows={4}
            name="options"
            defaultValue={JSON.stringify(store.options)}
            label="Item Options"
            variant="outlined"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
          <span>
            <Button
              disabled={disableSave()}
              type="submit" variant="contained" color="primary">
              Create Shop <Publish />
            </Button>
          </span>
        </form>
      </Card>
    </>
  );
}
