import { AccountBox, Chat, ExitToApp, HelpCenter, Home, Login, StoreMallDirectory } from "@mui/icons-material";
import { Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

function AppDrawer({ currentUser, drawerOpen, setDrawerOpen, setShowLogin, logOut }) {
  const { pathname } = useLocation();
  let user = {};
  try {
    user = currentUser?.customData || {}
  } catch (ex) {
    console.warn(ex.message);
  }


  return (
    <Drawer
      variant="temporary"
      anchor={'right'}
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <List>
        <ListItemButton component={Link} to={'/'}
          selected={pathname === '/'}
          onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        {currentUser ?
          <ListItemButton component={Link} to={'/profile'}
            selected={pathname === '/profile'}
            onClick={() => setDrawerOpen(false)}>
            <ListItemIcon>
              <AccountBox size="small" />
            </ListItemIcon>
            <ListItemText primary={`Profile ${(user?.fullname ||'')}`} />
          </ListItemButton> :
          <ListItemButton onClick={() => {
            setShowLogin(true)
            setDrawerOpen(false)
          }}>
            <ListItemIcon>
              <Login />
            </ListItemIcon>
            <ListItemText primary="Log in" />
          </ListItemButton>}
      </List>
      <Divider />
      {user.isAdmin &&
        <List subheader={<ListSubheader>Admin settings</ListSubheader>}>
          <ListItemButton sx={{ pl: 4 }} component={Link}
            to={'/shops'}
            selected={pathname === '/shops'}
            onClick={() => setDrawerOpen(false)}>
            <ListItemIcon>
              <StoreMallDirectory />
            </ListItemIcon>
            <ListItemText primary="Shops" />
          </ListItemButton>
        </List>}
      <List>
        {currentUser &&
        <>
          <ListItemButton
            onClick={async () => {
              await logOut()
              setDrawerOpen(false);
            }}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItemButton>
        <ListItemButton component={Link}
          to={'/chat'} onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <Chat />
          </ListItemIcon>
          <ListItemText primary="Chat" />
        </ListItemButton>
        </>
        }
        <ListItemButton component={Link}
          to={'/about'} onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <HelpCenter />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
      </List>
    </Drawer>
  )
}

export default AppDrawer;