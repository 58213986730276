import React from "react";
import { Link as RLink, useParams } from "react-router-dom";
import { BSON } from "realm-web";
import {
  Container,
  LinearProgress,
  Divider,
  Typography,
  Button,
  ButtonGroup
} from "@mui/material";

import { useShowLoader } from "../../../hooks/util-hooks";
import { useRealmApp } from "../../RealmApp";
import { useStores } from "./useStores";
import { StoreEditForm } from "./StoreEditForm";
import { StorePaymentKeys } from "./StorePaymentKeys";
import { Search } from "@mui/icons-material";

export function ShopEditPage() {
  const { currentUser } = useRealmApp();
  const { shopId } = useParams();
  const { loading, stores, ...actions } = useStores();
  const showLoader = useShowLoader(loading, 200);

  function filterShop(stores) {
    return stores.find(s => BSON.ObjectID(s._id).toString() === shopId)
  }

  return (
    <Container disableGutters={true} maxWidth="sm">
      {loading ? (showLoader ? (<LinearProgress />) : <div />) : (
        <>
          <Divider sx={{ my: 2 }} />
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button component={RLink} to={`/shop/${shopId}/stats`}>KPIs</Button>
            <Button component={RLink} to={`/shop/${shopId}/events`} endIcon={<Search />}>
              Order Events
            </Button>
            <Button component={RLink} to={`/shop/${shopId}/categories`}>
              Categories
            </Button>
          </ButtonGroup>
          <Divider sx={{ my: 2 }} />
          <StoreEditForm store={filterShop(stores)} actions={actions} currentUser={currentUser} />
          <Divider style={{ marginTop: 16 }} />
          <Typography variant="h5" sx={{ m: 1 }}>Payment Settings</Typography>
          <StorePaymentKeys store={filterShop(stores)} actions={actions} />
        </>
      )}
    </Container>
  );
}
