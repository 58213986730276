import { Button, Card, Container, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useUsers } from "../../hooks/useUsers";

export function PaypalOnboardPage() {
    const { upsertUser } = useUsers();
    const noErrors = { email: null, confirm: null };
    const [error, setError] = React.useState(noErrors);
    const [done, setDone] = React.useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const merchantEmail = searchParams.get('merchantId');
    const merchantId = searchParams.get('merchantIdInPayPal');
    const merchantAccountStatus = searchParams.get('accountStatus')

    const confirmClickHandler = async () => {
        try {
            // update merchantId for user
            upsertUser({merchantId});
            setError((p) => ({ ...p, confirm: null }))
            setDone(true)
            setTimeout(()=> {
                navigate('/profile');
              }, 1600)
        } catch (ex) {
            console.log('err:', ex.error);
            setError((p) => ({ ...p, confirm: ex.error }));
        }
    };

    return (
        <Container maxWidth="sm" className="main-container">
            <Card className="auth-card" variant="outlined">
                <Typography style={{ marginBottom: 15 }} variant="h6">Link Paypal Merchant Account </Typography>
                <Typography style={{ marginBottom: 15 }} variant="p"> 
                   Paypal Merchant ID: {merchantId} <br />
                   {merchantAccountStatus} for {merchantEmail} 
                </Typography>
                <Button variant="contained" color="primary" onClick={confirmClickHandler}>
                    YES - Approve
                </Button>
                {Boolean(error.confirm) && <Typography color="error">{error.confirm}</Typography>}
                {done && <h3>Link Complete!</h3>}
            </Card>
            {Boolean(error.confirm) ?
                <Card className="auth-card" variant="outlined">
                    <Typography style={{ marginBottom: 5 }}>Re-link your PayPal Merchant account</Typography>
                    <Link to={'/profile'} >Go to Profile</Link>
                </Card>
                : ''}
        </Container>
    )

}