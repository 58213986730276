export const toggleBoolean = (prev) => !prev;

export function filterOptions(options, key) {
  return options.map((i) => {
    if (i[key]) {
      return {key: i[key]};
    }
    return false;
  }).filter(a => a);
}

const isValidArrayIndex = (arr, idx) => {
  return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr, idx, value) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr, idx, newValue) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(arr, idx, updater) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr, idx) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export const isSameTodo = (todo1, todo2) =>
  String(todo1?._id) === String(todo2?._id);

export const getTodoIndex = (todos, todo) => {
  const idx = todos.findIndex((t) => isSameTodo(t, todo));
  return idx >= 0 ? idx : null;
};

export const isSameItem = (a, b) =>
  String(a?._id) === String(b?._id);

export const getItemIndex = (items, item) => {
  const idx = items.findIndex((t) => isSameItem(t, item));
  return idx >= 0 ? idx : null;
};

export function itemImageUrls(item) {
  const imageUrls = [item.imageUrl || ''];
  if (item.imageUrl2) {
    imageUrls.push(item.imageUrl2);
  }
  if (item.imageUrl3) {
    imageUrls.push(item.imageUrl3);
  }
  if (item.imageUrl4) {
    imageUrls.push(item.imageUrl4);
  }
  if (item.imageUrl5) {
    imageUrls.push(item.imageUrl5);
  }
  return imageUrls
}

/**
 * @param {string} token 
 */
export function isTokenExpired(token) {
  if (!token) {
    return false;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const { exp } = JSON.parse(jsonPayload);
  const expired = Date.now() >= exp * 1000
  return expired
}

/**
 * @param {string} imageUrl 
 * @param {object} obj
 * @param {boolean} [obj.large]
 * @param {object} [obj.imageSize]
 */
export function itemImageURL(imageUrl, { imageSize, large } = {}) {
  let dimensions = large ? { width: 800, height: 900 } : { width: 600, height: 600 };

  // CF images
  if (imageUrl?.includes('imagedelivery.net')) {
    const variant = large ? 'itemlarge' : 'itemsmall';
    return `${imageUrl}/${variant}`;
  }

  // Cloudinary
  if (imageSize?.width && imageSize?.height) {
    dimensions = imageSize;
  }
  const resize = `image/upload/c_pad,b_lightgrey,w_${dimensions.width},h_${dimensions.height}/`;
  return imageUrl?.split('image/upload/').join(resize) || '';
}