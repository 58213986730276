import { DeleteForeverRounded, Publish } from "@mui/icons-material";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import React from "react";
import { useDeliveryOptions } from "../../../hooks/useDeliveryOptions";

export function UserDeliveryOptions({store}) {
    const [isEditMode, setEditMode] = React.useState(false);
    const [isFormDirty, setFormDirty] = React.useState(false);
    const { items, addDeliveryOption, deleteDeliveryOption } = useDeliveryOptions();

    const handleInputChange = (event) => {
        setFormDirty(true);
      };

    const handleSave = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const {name, value} = Object.fromEntries(formData.entries());
        console.log('add delivery option:',{name, value});
        await addDeliveryOption({name, value});
        setEditMode(false);
      };

    return (
        <span>
        <Table sx={{ maxWidth: 380 }} size="small" aria-label="stores table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {items.map((c) => (
          <TableRow key={c._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">{c.name}</TableCell>
            <TableCell>{c.value || '--'}</TableCell>
            <TableCell>
            <Button
                size="small"
                onClick={() => {
                    console.log('delete?', {c});
                    deleteDeliveryOption(c);
                }}>
              <DeleteForeverRounded />
             </Button>
            </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
      <Button variant="contained" size="small" color="primary"
          onClick={(e) => {
            setEditMode(true);
            setFormDirty(false);
        }}>Add Delivery Option</Button>

        {isEditMode &&
        <form className="consignor-edit-form" onSubmit={handleSave}>
            <TextField
                id="input-name"
                size="small"
                name="name"
                label="Name"
                onChange={handleInputChange}
                variant="outlined"
            />
            <TextField
                id="input-value"
                size="small"
                name="value"
                label="value"
                onChange={handleInputChange}
                variant="outlined"
            />
            <Button disabled={!isFormDirty} 
              style={{marginRight:0}} type="submit" 
              variant="contained" color="primary">
              Save <Publish />
            </Button>
        </form>}
        </span>
    )
}