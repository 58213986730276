import React from "react";
import { Box, Container, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export function TermsPage() {

  return (
    <main>
      <Box sx={{ bgcolor: 'background.paper', pt: 3, pb: 6 }}>
        <Container maxWidth="sm">
          <h1>Our Terms of Use</h1>
          <p>
            Below are some terms all SwagShops users must agree to.
          </p>
          <p>
            If you have any questions about these Terms, please contact us. For information about how we collect, use, share and otherwise process information about you, please see our <Link to={"/about/privacy"}>Privacy Notice</Link>.
          </p>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: "<iframe title='Terms' frameborder='0' height='1000' width='100%' src='/content/terms.html' />" }} />
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center">
          </Stack>
          <a target="_blank" href="/content/terms.html">Printable link</a>
        </Container>
      </Box>
    </main>
  );
}

