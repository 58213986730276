import React, { useCallback } from "react";
import { useUploady } from "@rpldy/uploady";
import { Button } from "@mui/material";
import PhotoCamera from '@mui/icons-material/PhotoCamera';

export function ProfileUploadButton({ buttonTitle, uploaded, children }) {
  const { showFileUpload } = useUploady();

  const onButtonClick = useCallback((e) => {
    console.log('showFileUpload dialog', e.target)
    // showFileUpload(uploadOptionsRef.current);
    showFileUpload()
  }, [showFileUpload]);

  return <Button
    size="small"
    style={{ float:'right' }}
    disabled={uploaded}
    variant="outlined"
    endIcon={<PhotoCamera />}
    onClick={onButtonClick}>
    {buttonTitle}
    {children}
  </Button>;
}
