import React from "react";
import * as Realm from "realm-web";
import { Button, Card, CircularProgress, Divider, IconButton, InputAdornment, TextField } from "@mui/material";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { toggleBoolean } from "../../shared/helpers";
import { handleAuthenticationError } from "../../shared/handleAuthenticationError";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { EmailResetForm } from "./EmailResetForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FBLoginButton } from "../controls/FBLoginButton";
import { userEmailTaken } from "../../shared/getEndpoint";

export function LoginForm({ realmApp, setShowLogin, setIsSignup, isSignup, showFbLogin }) {
  const [showReset, setShowReset] = React.useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  function getLoginRedirectPath() {
    return searchParams.get('redirect');
  }

  const toggleIsSignup = () => {
    clearErrors();
    setIsSignup(toggleBoolean);
  };
  // Authentication errors
  const noErrors = {
    email: null,
    password: null,
    other: null,
  };
  const [error, setError] = React.useState(noErrors);
  const clearErrors = () => setError(noErrors);
  const NonAuthErrorAlert = useErrorAlert({
    error: error.other,
    clearError: () => {
      setError((prevError) => ({ ...prevError, other: null }));
    },
  });
  // Manage password visibility
  const [showPassword, setShowPassword] = React.useState(false);
  const [signUpLoading, setSignUpLoading] = React.useState(false);
  const [signUpComplete, setSignUpComplete] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(toggleBoolean);

  const onFormSubmit = async ({ email, password }) => {
    const lowerEmail = email.toLowerCase();
    clearErrors();
    try {
      if (isSignup) {
        setSignUpLoading(true);
        await userEmailTaken(lowerEmail);
        await realmApp.emailPasswordAuth.registerUser({ email: lowerEmail, password });
        setSignUpLoading(false);
        setSignUpComplete(true);
        return; // dont close Login after signup
      } else {
        await realmApp.logIn(Realm.Credentials.emailPassword(lowerEmail, password));
      }
      if (getLoginRedirectPath()) {
        navigate(getLoginRedirectPath());
      }
      setShowLogin(false);
    } catch (err) {
      setSignUpLoading(false);
      handleAuthenticationError(err, setError);
    }
  };

  return (
    <span>
      {showReset ?
        <EmailResetForm realmApp={realmApp} /> :
        <Card className="auth-card" variant="outlined">
          <form
            className="auth-form"
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              const { email, password } = Object.fromEntries(formData.entries());
              onFormSubmit({ email, password });
            }}
          >
            <NonAuthErrorAlert />
            <TextField
              id="input-email"
              name="email"
              label="Email Address"
              variant="outlined"
              autoComplete="username"
              error={Boolean(error.email)}
              helperText={error.email ?? ""}
            />
            {signUpComplete ? <span>Check your email for a link to <b>'Confirm your Email'</b></span>: ""}
            <TextField
              id="input-password"
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              variant="outlined"
              autoComplete={isSignup ? "new-password":"current-password"}
              error={Boolean(error.password)}
              helperText={error.password ?? ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" variant="contained" color="primary">
              {isSignup ? "Create Account" : "Log In"}
              {signUpLoading ? <CircularProgress color="info" sx={{ml:1}} size={20} /> : ""}
            </Button>
          </form>
          {showFbLogin &&
            <>
              <Divider sx={{ my: 1 }}>or</Divider>
              <FBLoginButton
                realmApp={realmApp}
                setShowLogin={setShowLogin} />
            </>
          }
          <Divider sx={{ my: 2 }}></Divider>
          <button
            type="button"
            className="link-button"
            onClick={() => toggleIsSignup()}
          >
            {isSignup
              ? "Already have an account? Log In"
              : "Sign up for an account"}
          </button>
          <Divider sx={{ mt: 3, mb: 2 }}></Divider>
          <button
            type="button"
            className="link-button"
            onClick={() => setShowReset(true)}>
            Reset Password
          </button>
          <Divider sx={{ my:1 }}></Divider>
          <button
            type="button"
            className="link-button"
            onClick={() => {
              navigate("/confirm", {test:'yy'})
              setShowLogin(false);
            }}>
            Confirm Email
          </button>
        </Card>}
    </span>
  )
}