import { CloseRounded, Edit, Publish } from "@mui/icons-material";
import { Button, FormControl, Radio, FormControlLabel, FormLabel, RadioGroup, TextField, Typography, Dialog, DialogTitle, IconButton, DialogContent, Card } from "@mui/material";
import React from "react";
import PaypalSecretEdit from "./PaypalSecretEdit";

export function StorePaymentKeys({ store, actions }) {
    const [open, setOpen] = React.useState(false);
    const [isFormDirty, setFormDirty] = React.useState(false);
    const [paypalDevMode, setPaypalDevMode] = React.useState(store.paypalDeveloperMode || 'sandbox');

    function handleClose(value) {
        setOpen(false);
    }

    function disableSave() {
        return !isFormDirty;
    }
    const noErrors = {
        name: null, paypalClientId: null
    };

    const [error] = React.useState(noErrors);

    function handlePaypalModeChange(e) {
        console.log(e.target.value);
        setPaypalDevMode(e.target.value);
        setFormDirty(true);
    }

    function handleInputChange(e) {
        setFormDirty(true);
    }

    return (
        <>
            <Card sx={{ p: 2 }} variant="outlined">
                <form
                    sx={{ pt: 2, mb: 2 }}
                    style={{ display: "flex", flexDirection: "column", gap: 22 }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        const formData = new FormData(e.target);
                        const { paypalDeveloperMode, paypalClientId_live, paypalClientId_sandbox } = Object.fromEntries(formData.entries());
                        store.paypalDeveloperMode = paypalDeveloperMode;
                        store.paypalClientId = {
                            live: paypalClientId_live,
                            sandbox: paypalClientId_sandbox
                        }
                        store.updated_ts = Date.now();
                        console.log('update Store:', { store }, { paypalDeveloperMode });
                        await actions.updateStore(store);
                        setFormDirty(false);
                    }}
                >
                    <Typography variant="h6" sx={{ m: 2 }}>
                        Paypal Keys
                    </Typography>
                    <TextField
                        id="input-paypalClientId_sandbox"
                        autoComplete="off"
                        defaultValue={store.paypalClientId?.sandbox}
                        name="paypalClientId_sandbox"
                        label="Paypal ClientId (sandbox)"
                        variant="outlined"
                        error={Boolean(error.paypalClientId)}
                        onChange={handleInputChange}
                        helperText={error.paypalClientId ?? ""}
                    />
                    <TextField
                        id="input-paypalClientId_live"
                        autoComplete="off"
                        defaultValue={store.paypalClientId?.live}
                        name="paypalClientId_live"
                        label="Paypal ClientId (live)"
                        variant="outlined"
                        error={Boolean(error.paypalClientId)}
                        onChange={handleInputChange}
                        helperText={error.paypalClientId ?? ""}
                    />
                    <Button variant="outlined" startIcon={<Edit />}
                        onClick={() => {
                            setOpen(true);
                        }}>
                        Edit Secrets
                    </Button>
                    <FormControl>
                        <FormLabel id="paypalDeveloperModeLabel">Developer mode:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="paypalDeveloperModeLabel"
                            name="paypalDeveloperMode"
                            value={paypalDevMode}
                            onChange={handlePaypalModeChange}
                        >
                            <FormControlLabel value="sandbox" control={<Radio />} label="Sandbox" />
                            <FormControlLabel value="live" control={<Radio />} label="Live" />
                        </RadioGroup>
                    </FormControl>
                    <Button
                        disabled={disableSave()}
                        type="submit" variant="contained" color="primary">
                        Save <Publish />
                    </Button>
                </form>
            </Card>
            <Dialog fullScreen={false} onClose={handleClose} open={open}>
                <DialogTitle>Paypal Secrets
                    <IconButton style={{ float: 'right' }} onClick={() => setOpen(false)}>
                        <CloseRounded />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <PaypalSecretEdit store={store} actions={actions} />
                </DialogContent>
            </Dialog>
        </>
    )
}