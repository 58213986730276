import React from "react";
import Uploady, { UPLOADER_EVENTS } from "@rpldy/uploady";
import { CircularProgress } from "@mui/material";
import { ProfileUploadButton } from "./ProfileUploadButton";
const CLOUD_NAME = "dg6z5xnnv";
const UPLOAD_PRESET = "unsigned_ninja";

function ProfileImageUpload({ buttonTitle, upsertUser, children }) {
    const [loading, setLoading] = React.useState(false);
    const [uploaded, setUploaded] = React.useState(false);
    // const { showFileUpload } = useUploady();

    const listeners = React.useMemo(() => ({
        [UPLOADER_EVENTS.ITEM_START]: (item) => {
            setLoading(true);
            console.log(`Item Start - ${item.id} : ${item.file.name}`);
        },
        [UPLOADER_EVENTS.ITEM_FINISH]: (item) => {
            setLoading(false);
            setUploaded(true);
            console.log(`Item Finish - ${item.id} : ${item.file.name}`);
        },
    }), []);

    const resFormatter = React.useCallback((res, status, headers) => {
        const response = JSON.parse(res)
        console.log("### URL:", response.secure_url, status);
        upsertUser({ imageUrl: response.secure_url });
        return `${status} - OK!`;
    }, [upsertUser]);

    return (
        <Uploady
            listeners={listeners}
            multiple={false}
            grouped
            maxGroupSize={2}
            method="PUT"
            formatServerResponse={resFormatter}
            destination={{
                url: `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`,
                params: {
                    upload_preset: UPLOAD_PRESET,
                }
            }}>
            <ProfileUploadButton buttonTitle={buttonTitle} uploaded={uploaded}>
                {children}
            </ProfileUploadButton>
            {loading && <CircularProgress />}
        </Uploady>);
}

export default ProfileImageUpload;