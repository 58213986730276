import React from "react";
import { DeleteForeverRounded, Publish } from "@mui/icons-material";
import { Button, Divider, List, ListItem, ListItemText, ListSubheader, TextField } from "@mui/material";

export function StoreSellersList({store, actions, createInvite, removePendingInvite }) {
    const [addMode, setAddMode] = React.useState(false);
    const [deleted, setDeleted] = React.useState(false);

    const handleSave = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const { email: emailInput } = Object.fromEntries(formData.entries());
        const email = emailInput.toLowerCase()
        if (Array.isArray(store.sellers)) {
            if (!store.sellers.includes(email) || store.sellers.length>10) {
                store.sellers.push(email);
            } else {
                throw Error('Duplicate seller email')
            }
        } else {
            store.sellers = [email]
        }
        createInvite({email, kind:'seller', store});
        await actions.updateStore(store);
        setAddMode(false);
    };

    return (
        <List style={{ width: 360 }} dense={true} subheader={<ListSubheader>Sellers</ListSubheader>}>
            {store.sellers && store.sellers?.map((s, idx) => (
                <span key={s}>
                    <ListItem secondaryAction={
                        <Button
                            size="small"
                            onClick={async (e) => {
                                // Delete domain
                                store.sellers = store.sellers.filter(seller => seller !== s);
                                await actions.updateStore(store);
                                setDeleted(true);
                                removePendingInvite({email:s, store});
                            }}>
                            <DeleteForeverRounded />
                        </Button>}>
                        <ListItemText primary={s} />
                    </ListItem>
                    <Divider />
                </span>
            ))}
            <ListItem>
                <Button variant="contained" size="small" color="primary"
                    onClick={(e) => {
                        setAddMode(true);
                        setDeleted(false);
                    }}>[+] Add</Button>
                {deleted && " ...deleted"}
            </ListItem>
            <ListItem>
                {addMode &&
                    <form className="consignor-edit-form" onSubmit={handleSave}>
                        <TextField
                            id="input-email"
                            size="small"
                            name="email"
                            label="Email"
                            variant="outlined"
                        />
                        <Button
                            style={{ marginRight: 0 }} size="medium" type="submit"
                            variant="contained" color="primary">
                            Save <Publish />
                        </Button>
                    </form>}
            </ListItem>
        </List>
    )
}