import { Button, Card, TextField, Typography } from "@mui/material";
import React from "react";

export function EmailResetForm({ realmApp }) {
  const [error, setError] = React.useState('');
  const resetEmail = React.useRef();
  const [emailSent, setEmailSent] = React.useState(false);

  return (
    <span>
      {!emailSent ?
        <Card className="auth-card" variant="outlined">
          <Typography style={{ marginBottom: 5 }} variant="h6">Reset password</Typography>
          <TextField
            id="input-reset-email"
            inputRef={resetEmail}
            name="email"
            label="Email Address"
            variant="outlined"
            error={Boolean(error)}
            helperText={error ?? ""}
          />
          <Button variant="contained" color="primary"
            onClick={async () => {
              try {
                await realmApp.emailPasswordAuth.sendResetPasswordEmail({ email: resetEmail.current.value });
                setError('')
                setEmailSent(true)
              } catch (ex) {
                console.log('err:', ex.error);
                setError(ex.error);
              }
            }}
          >Send email</Button>
        </Card> : "Email Sent!"}
    </span>
  )
}