import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { LoginForm } from "../forms/LoginForm";
import { useRealmApp } from "../RealmApp";

export function LoginDialog({ open, setShowLogin, isSignupDefault = false }) {
    const realmApp = useRealmApp();
    const [isSignup, setIsSignup] = React.useState(isSignupDefault);

    const handleClose = (value) => {
        setShowLogin(false);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={false}>
            <DialogTitle>{isSignup ? "Sign up" : "Log In"}</DialogTitle>
            <DialogContent>
                <LoginForm
                    realmApp={realmApp}
                    isSignup={isSignup}
                    showFbLogin={true}
                    setIsSignup={setIsSignup}
                    setShowLogin={setShowLogin} />
            </DialogContent>
        </Dialog>
    )
}