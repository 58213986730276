import React from "react";
import {
  Container,
  Typography,
  List,
  LinearProgress,
  Button,
} from "@mui/material";

import { useShowLoader } from "../../../hooks/util-hooks";
import { useRealmApp } from "../../RealmApp";
import { useStores } from "./useStores";
import { ShopsTable } from "./ShopsTable";
import { Add } from "@mui/icons-material";
import { Link as RLink } from "react-router-dom";

export function ShopsPage() {
  const { currentUser } = useRealmApp();
  const { loading, stores, ...actions } = useStores();
  const showLoader = useShowLoader(loading, 200);

  return (
    <Container disableGutters={true} maxWidth="sm">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : (
          <div />
        )
      ) : (
        <div style={{ marginTop: 5 }}>
          <div>
            <Typography style={{ marginLeft: 10 }} variant="h6">
              {`${stores.length} shop${stores.length === 1 ? "" : "s"}`}
            </Typography>
            {currentUser.customData.isAdmin && (
              <Button
                component={RLink}
                to={"/shop/new"}
                sx={{ m: 1 }}
                size="small"
                variant="contained"
                color="primary"
                endIcon={<Add />}
                onClick={(e) => {
                  // const newStore = actions.createNewStore();
                  // stores.push(newStore);
                }}
              >
                Create New Shop
              </Button>
            )}
          </div>
          <List style={{ width: "100%" }}>
            <ShopsTable
              actions={actions}
              currentUser={currentUser}
              items={stores}
            />
          </List>
        </div>
      )}
    </Container>
  );
}
