import React from "react";
import * as Realm from "realm-web";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
const { dataSourceName } = Config();


export function useUsers() {
  const realmApp = useRealmApp();
  const { currentUser } = useRealmApp();
  const [userSaved, setUserSaved] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState({});

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "users"
  });

  React.useEffect(() => {
    collection?.aggregate([
      {
        $lookup:
        {
          from: "stores",
          localField: "id",
          foreignField: "admins",
          as: "stores"
        }
      },
      {
        $lookup:
        {
          from: "invites",
          localField: "email",
          foreignField: "email",
          as: "invites"
        }
      }]).then((res) => {
        const [user] = res
        console.log('useUsers.js fetch:mongo', { user });
        setUser(user);
        setLoading(false);
      });
  }, [collection]);

  const upsertUser = async (profile) => {
    profile.updated_ts = Date.now();

    const res = await collection.updateOne(
      { id: realmApp.currentUser.id },
      { $set: profile }
    );
    console.log('upsertUser:', res);
    const user = await collection.findOne({ id: realmApp.currentUser.id })
    setUserSaved(true);
    setUser(user);
  };

  // build and insert an order
  const insertUser = async (user) => {
    if (user.id) {
      user._id = new Realm.BSON.ObjectID();
      user._partition = realmApp.currentUser.id;
      user.id = realmApp.currentUser.id;
      try {
        //console.log({ order })
        await collection.insertOne(user);
        setUserSaved(true);
      } catch (err) {
        if (err.error.match(/^Duplicate key error/)) {
          console.warn(
            `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
          );
        }
        console.error(err);
      }
    }
  };

  const updateUserInfo = async (user, venmo, fullname) => {
    await collection.updateOne(
      { _id: user._id },
      { $set: { venmo, fullname } }
    );
  };

  // const deleteUser = async (user) => {
  //   await collection.deleteOne({ _id: user.id });
  // };

  return {
    user,
    setUser,
    currentUser,
    loading,
    userSaved,
    upsertUser,
    insertUser,
    updateUserInfo,
  };
}
