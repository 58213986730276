import {
  ThemeProvider as MuiThemeProvider, createTheme,
  // useMediaQuery,
} from "@mui/material";
// import { pink } from '@mui/material/colors';
// import { useRealmApp } from "./RealmApp";

export function ThemeProvider({ children }) {
  const store = { name: 'SwagShops', theme: '' };
  const prefersDarkMode = false; // useMediaQuery('(prefers-color-scheme: dark)');

  const defaultThemeConfig = {
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: { main: '#202020' },
      secondary: { main: '#EEEEEE' },
      neutral: {
        text:'#202020',
        main: '#64748B',
        contrastText: '#fff',
      }
    }
  };
  const theme1 = createTheme(defaultThemeConfig);
  let theme = theme1;

  let themeConfig = defaultThemeConfig;
  if (store.theme) {
    console.log('store.theme:', store.theme);
    themeConfig.palette.primary.main = store.theme.split(',')[0];
    themeConfig.palette.secondary.main = store.theme.split(',')[1];
    try {
      theme = createTheme(themeConfig);
    } catch (ex) {
      console.error(ex);
    }
  }

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
