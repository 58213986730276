import { Link, Box, Typography } from "@mui/material";

export function Footer() {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
    <Typography
      variant="subtitle1"
      align="center"
      color="text.secondary"
      component="p"
    >
      Build your business along with your brand.
    </Typography>
    <Copyright />
  </Box>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.swag-shops.com/">
        SwagShops
      </Link>{' '}
      {new Date().getFullYear()}
      {'. / '}
      <Link color="inherit" href="https://www.swag-shops.com/about">
        Privacy & Terms
      </Link>
    </Typography>
  );
}
