import { Card, CardActions, CardContent, CardMedia, Grid, Link, Typography } from "@mui/material";

function ImageGrid({card}) {
    return ( 
        <Grid item key={card.id} xs={12} sm={6} md={4}>
               <Link href={card.url} alt={card.summary} >
              <Card 
              sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
               
                <CardMedia
                  component="img"
                  sx={{
                    height:200
                    // 16:9
                    // pt: '56.25%',
                  }}
                  image={card.imageUrl}
                  alt="random"
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.name}
                  </Typography>
                  <Typography>
                    {card.summary}
                  </Typography>
                </CardContent>
                <CardActions>
                  {/* <Button size="small" component={Link} target="_blank" href={card.url}>
                    View
                  </Button> */}
                  {/* <Button size="small">Edit</Button> */}
                </CardActions>
              </Card>
              </Link>
            </Grid>
     );
}

export default ImageGrid;