import React from "react";
import { DeleteForeverRounded, Publish } from "@mui/icons-material";
import { Button, Divider, List, ListItem, ListItemText, ListSubheader, TextField } from "@mui/material";

export function StoreDomainEdit({ item, actions }) {
    const [addDomainMode, setAddDomainMode] = React.useState(false);
    const [deleted, setDeleted] = React.useState(false);

    const handleDomainSave = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const { name } = Object.fromEntries(formData.entries());
        item.domains.push(name);
        await actions.updateStore(item);
        setAddDomainMode(false);
    };

    return (
        <List style={{ width: 360 }} dense={true} subheader={<ListSubheader>Domains</ListSubheader>}>
            {item.domains?.map((d, idx) => (
                <span key={d}>
                    <ListItem secondaryAction={
                        <Button
                            disabled={idx === 0}
                            size="small"
                            onClick={async (e) => {
                                // Delete domain
                                item.domains = item.domains.filter(dom => dom !== d);
                                await actions.updateStore(item);
                                setDeleted(true);
                            }}>
                            <DeleteForeverRounded />
                        </Button>}>
                        <ListItemText primary={d} />
                    </ListItem>
                    <Divider />
                </span>
            ))}
            <ListItem>
                <Button variant="contained" size="small" color="primary"
                    onClick={(e) => {
                        setAddDomainMode(true);
                        setDeleted(false);
                    }}>[+] Add</Button>
                {deleted && " ...deleted"}
            </ListItem>
            <ListItem>
                {addDomainMode &&
                    <form className="consignor-edit-form" onSubmit={handleDomainSave}>
                        <TextField
                            id="input-name"
                            size="small"
                            name="name"
                            label="Name"
                            variant="outlined"
                        />
                        <Button
                            style={{ marginRight: 0 }} size="medium" type="submit"
                            variant="contained" color="primary">
                            Save <Publish />
                        </Button>
                    </form>}
            </ListItem>
        </List>
    )
}