import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function RedirectPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url');

  React.useEffect(()=> {
    if (url) {
      console.log({url});
      navigate(url);
    }
  },[url, navigate])

  return (<div></div>);
}
