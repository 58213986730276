import React from "react";
import Debug from "debug";
import {
    Typography,
    Divider,
    Card,
    CardHeader,
    CardContent,
    Switch,
    FormControlLabel,
    Button,
    CircularProgress
} from "@mui/material";
import { ProfileInvites } from "./ProfileInvites";
import { AccountBalance, Warning } from "@mui/icons-material";
import { ConsignorEdit } from "./ConsignorEdit";
import { UserDeliveryOptions } from "./UserDeliveryOptions";
import { MerchantAccountStatus } from "./MerchantAccountStatus";
import { getOnboardStatus } from "../../../shared/getEndpoint";
const debug = Debug("SellerSettings");

export function SellerSettings({ store, user, currentUser, upsertUser }) {
    const [sellingEnabled, setSellingEnabled] = React.useState(user.sellingEnabled);
    const [sellerStatus, setSellerStatus] = React.useState({});
    const [openLoading, setOpenLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function onSellerStatusChange(e) {
        debug('onSellerStatusChange:', e.target.checked);
        setSellingEnabled(e.target.checked);
        upsertUser({ sellingEnabled: e.target.checked })
    }

    async function handleMerchantStatus(e) {
        setOpenLoading(true);
        const _sellerStatus = await getOnboardStatus(user.merchantId);
        _sellerStatus.updated_ts = new Date();
        setOpenLoading(false);
        debug('handleMerchantStatus', {_sellerStatus});
        setSellerStatus(_sellerStatus);
        upsertUser({ sellerStatus: _sellerStatus })
        setOpen(true);
    }

    return (
        <>
            {user?.invites?.length ?
                <Card sx={{ ml: 0, p: 2 }}>
                    <h3>Invites</h3>
                    <ProfileInvites user={user} currentUser={currentUser} invites={user.invites} upsertUser={upsertUser} />
                </Card> : ''}
            {user?.sellerStores?.length ?
                <Card style={{ marginTop: 9 }}>
                    <CardHeader style={{ paddingBottom: 0 }} title="Seller settings" />
                    <CardContent>
                        <div>
                            {user.merchantId ?
                                <Typography>
                                    Paypal Merchant ID:
                                    <Button onClick={handleMerchantStatus}>
                                    <>{openLoading ? <CircularProgress color="info" /> : <AccountBalance />} &nbsp; {user.merchantId}</>
                                    </Button>
                                </Typography>
                                :
                                <Typography>
                                    <Warning sx={{ mr: 1 }} color="warning" />
                                     Connect Paypal Merchant account
                                </Typography>
                            }
                            <Divider textAlign="left" sx={{ my: 2 }}></Divider>
                        </div>
                        <Typography variant="h6">
                            Selling Enabled: &nbsp;
                            <FormControlLabel
                                control={
                                    <Switch checked={Boolean(sellingEnabled)}
                                        onChange={onSellerStatusChange} color="success" />
                                }
                                label={sellingEnabled ? "True" : "False"} />
                        </Typography>
                        <Divider textAlign="left" sx={{ my: 2 }}></Divider>
                        {store.isConsignment ?
                            <div>
                                <Typography variant="p">Consignors</Typography>
                                <ConsignorEdit store={store} />
                            </div> : ""}
                        <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}></Divider>
                        <div>
                            <Typography variant="p">Delivery options</Typography>
                            <UserDeliveryOptions store={store} />
                        </div>
                    </CardContent>
                </Card>
                : null}
            <MerchantAccountStatus
                open={open}
                sellerStatus={sellerStatus}
                setShowDialog={setOpen}
            />
        </>
    )
}