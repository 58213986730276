import React from "react";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import ImageGrid from "../controls/ImageGrid";
import { Link } from "react-router-dom";

export function HomePage({currentUser}) {

  const cards = [
    { id: 1, name: 'Ninja Duds', summary:'Consignment shop with re-sale clothing', url: 'https://www.ninjaduds.com', imageUrl: 'https://res.cloudinary.com/dg6z5xnnv/image/upload/v1673732154/swagshops/Screen_Shot_2023-01-14_at_4.35.40_PM_hf0p7h.png' },
    { id: 2, name: 'Swag (demo) Shop', summary:'A SwagShop demo site', url: 'https://demo.swag-shops.com', imageUrl: 'https://res.cloudinary.com/dg6z5xnnv/image/upload/v1673884576/swagshops/Screen_Shot_2023-01-16_at_10.55.35_AM_cggnui.png' },
    { id: 3, name: 'Web3 (demo) Shop', summary:'Another demo site', url: 'https://www.web3-shops.com', imageUrl: 'https://res.cloudinary.com/dg6z5xnnv/image/upload/v1673884576/swagshops/Screen_Shot_2023-01-16_at_10.55.35_AM_cggnui.png' },
  ];

  const resize = `e_brightness:23,w_${1280},h_${1280}`
  const imgBaseUrl = `https://res.cloudinary.com/dg6z5xnnv/image/upload/${resize}/v1673625867/swagshops`
  const heroImg = `${imgBaseUrl}/blurry_shop_qq3upw.jpg`  // photo-1673192191311-5e731b6d5545_skfhbi.jpg
  // const colorA ='#04264B' // https://www.magicpattern.design/tools/css-backgrounds
  // const colorB ='#000' // backColoer
  
  return (
    <main>
      <Box sx={{
        borderRadius:'9px',
        opacity:'0.9',
        // backgroundSize: '32px 56px',
        // backgroundPosition:'0 0, 0 0, 16px 28px, 16px 28px, 0 0, 16px 28px',
        // backgroundImage: `linear-gradient(30deg, ${colorA} 12%, transparent 12.5%, transparent 87%, ${colorA} 87.5%,${colorA}), linear-gradient(150deg, ${colorA} 12%, transparent 12.5%, transparent 87%, ${colorA} 87.5%, ${colorA}), linear-gradient(30deg, ${colorA} 12%, transparent 12.5%, transparent 87%, ${colorA} 87.5%, ${colorA}), linear-gradient(150deg, ${colorA} 12%, transparent 12.5%, transparent 87%, ${colorA} 87.5%, ${colorA}), linear-gradient(60deg, ${colorB} 25%, transparent 25.5%, transparent 75%, ${colorB} 75%, ${colorB}), linear-gradient(60deg, ${colorB} 25%, transparent 25.5%, transparent 75%, ${colorB} 75%, ${colorB})`,
        backgroundImage: `url(${heroImg})`,
        pt: 8, pb: 6,
        bgcolor: 'background.paper'
        }}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="neutral.text"
            gutterBottom>
           Build your own marketplace
          </Typography>
          <Typography variant="h5" align="center" color="neutral.text" paragraph>
            SwagShops is an e-commerce platform for the mobile merchant.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center">
            {!currentUser === "disabled" && 
              <Button component={Link} to={"/seller-signup"} variant="contained">Sign-up now</Button>
            }
            <Button component={Link} to={"/about"} variant="contained">More Info</Button>
          </Stack>
        </Container>
      </Box>
      <Container sx={{ py: 8 }} maxWidth="md">
        <Typography variant="h5" align="left" paragraph>
          Example shops
        </Typography>
        <Grid container spacing={4}>
          {cards.map((card) => (
            <ImageGrid key={card.id} card={card} />
          ))}
        </Grid>
      </Container>
    </main>
  );
}
