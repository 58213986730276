import React from "react";
import { LoginDialog } from "../controls/LoginDialog";
import { HomePage } from "./HomePage";

export function Login({ store, isSignup }) {
  const [showLogin, setShowLogin] = React.useState(true);
  return (
    <>
      <LoginDialog
        open={showLogin}
        isSignupDefault={isSignup}
        setShowLogin={setShowLogin}
      />

      <HomePage store={store} />
    </>
  );
}
