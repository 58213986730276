import { Link, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { timeAgo } from "../../hooks/util-hooks";
import ItemImage from "../controls/ItemImage";

function ViewOrder({ order }) {
  return (
    <>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Order.id</TableCell>
            <TableCell>{order._id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>payment.create_time</TableCell>
            <TableCell>
              {new Date(order.payment.create_time).toLocaleDateString()} -
              {new Date(order.payment.create_time).toLocaleTimeString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>View Item</TableCell>
            <TableCell>
              <Link
                target="_blank"
                // TODO: make URL Dynamic
                href={`https://www.ninjaduds.com/items/${order.item?._id}`}
              >
                {order.item?._id}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.Title</TableCell>
            <TableCell>{order.item?.title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.category / category2</TableCell>
            <TableCell>{order.item?.category} / {order.item?.category2}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.Status</TableCell>
            <TableCell>{order.item?.status}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.Price</TableCell>
            <TableCell>${order.item?.price}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.applyDiscount</TableCell>
            <TableCell>{order.item?.applyDiscount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.discountPrice</TableCell>
            <TableCell>${order.item?.discountPrice}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.discountRate</TableCell>
            <TableCell>{order.item?.discountRate} % </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.Count</TableCell>
            <TableCell>{order.item?.inventoryCount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.Size</TableCell>
            <TableCell>{order.item?.size}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Item.Consignor</TableCell>
            <TableCell>{order.item?.consignor}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>order.created</TableCell>
            <TableCell>
              {timeAgo(order.created_ts)} | 
              {new Date(order.created_ts).toLocaleDateString()} -
              {new Date(order.created_ts).toLocaleTimeString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>paymentSource</TableCell>
            <TableCell>{order.data?.paymentSource}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Payment.Payer</TableCell>
            <TableCell>{order.payment?.payer?.name.given_name} {order.payment?.payer?.name.surname} [{order.payment?.payer?.email_address}]</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Payment.amount</TableCell>
            <TableCell>
              ${order.payment?.purchase_units[0]?.amount.value}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Paypal orderID</TableCell>
            <TableCell>{order.data?.orderID}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <ItemImage
                width={280}
                url={order.item?.imageUrl}
                alt={order.item?.title}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

export default ViewOrder;
