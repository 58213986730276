import React from "react";
import {
  Button,
  TextField,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  Card,
  Divider,
  Typography,
  Snackbar
} from "@mui/material";
import { Publish } from "@mui/icons-material";
import { useInvites } from "../../../hooks/useInvites";
import { StoreDomainEdit } from "./StoreDomainEdit";
import { StoreInvites } from "./StoreInvites";
import { StoreSellersList } from "./StoreSellersList";

export function StoreEditForm({ store, actions, currentUser }) {
  const [isFormDirty, setFormDirty] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const { invites, createInvite, removePendingInvite } = useInvites({ store });

  const admins = ['bradoyler@gmail.com']
  const isSuperAdmin = admins.includes(currentUser.profile.email);

  function disableSave() {
    // TODO: enable for store admins
    if (isSuperAdmin && isFormDirty) {
      return false;
    }
    return true;
  }

  const noErrors = {
    name: null,
    isConsignment: null,
    other: null,
  };

  const [error] = React.useState(noErrors);

  const handleInputChange = (event) => {
    setFormDirty(true);
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message="Shop settings saved"
      />
      <Card sx={{ p: 2 }} variant="outlined">
        <form className="auth-form"
          style={{ paddingTop: 6 }}
          onSubmit={async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const {
              name,
              isConsignment,
              categories,
              options,
              welcomeText,
              defaultDelivery,
              defaultShippingFee,
              enableInventory,
              enableSales
            } = Object.fromEntries(formData.entries());
            store.name = name;
            store.isConsignment = Boolean(isConsignment === 'true');
            store.enableInventory = Boolean(enableInventory === 'true');
            store.enableSales = Boolean(enableSales === 'true');
            if (categories) {
              store.categories = JSON.parse(categories);
            }
            if (options) {
              store.options = JSON.parse(options);
            }
            if (welcomeText) {
              store.welcomeText = welcomeText;
            }
            if (defaultDelivery) {
              store.defaultDelivery = defaultDelivery;
            }
            if (!isNaN(Number(defaultShippingFee))) {
              store.defaultShippingFee = Number(defaultShippingFee);
            }

            store.updated_ts = new Date();
            console.log('update:', { item: store }, { isConsignment });
            await actions.updateStore(store);
            setFormDirty(false);
            setSnackbarOpen(true);
          }}
        >
          <TextField
            id="input-name"
            defaultValue={store.name}
            name="name"
            label="Name"
            variant="outlined"
            error={Boolean(error.name)}
            onChange={handleInputChange}
            helperText={error.name ?? ""}
          />
          <FormControl>
            <InputLabel id="isConsignment-label">Enable Consignment?</InputLabel>
            <Select
              id="select-isConsignment"
              labelId="isConsignment-label"
              name="isConsignment"
              label="Enable Consignment?"
              defaultValue={store.isConsignment}
              onChange={handleInputChange}>
              <MenuItem key={String(true)} value={true}>True</MenuItem>
              <MenuItem key={String(false)} value={false}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="enableSales-label">Enable Inventory?</InputLabel>
            <Select
              id="select-enableInventory"
              labelId="enableInventory-label"
              name="enableInventory"
              label="Enable Selling?"
              defaultValue={store.enableInventory}
              onChange={handleInputChange}>
              <MenuItem key={String(true)} value={true}>True</MenuItem>
              <MenuItem key={String(false)} value={false}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="enableSales-label">Enable Selling?</InputLabel>
            <Select
              id="select-enableSales"
              labelId="enableSales-label"
              name="enableSales"
              label="Enable Selling?"
              defaultValue={store.enableSales}
              onChange={handleInputChange}>
              <MenuItem key={String(true)} value={true}>True</MenuItem>
              <MenuItem key={String(false)} value={false}>False</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="input-categories"
            multiline
            rows={4}
            name="categories"
            defaultValue={JSON.stringify(store.categories)}
            label="Item Categories"
            variant="outlined"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
          <TextField
            id="input-options"
            multiline
            rows={4}
            name="options"
            defaultValue={JSON.stringify(store.options)}
            label="Item Options"
            variant="outlined"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
          <TextField
            id="input-welcomeText"
            multiline
            rows={2}
            name="welcomeText"
            defaultValue={store.welcomeText}
            label="Welcome Text"
            variant="outlined"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
           <TextField
            id="input-defaultDelivery"
            name="defaultDelivery"
            defaultValue={store.defaultDelivery}
            label="Default Delivery"
            variant="outlined"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
           <TextField
            id="input-defaultShippingFee"
            name="defaultShippingFee"
            defaultValue={store.defaultShippingFee}
            label="Default ShippingFee"
            variant="outlined"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
          <span>
            <Button
              disabled={disableSave()}
              type="submit" variant="contained" color="primary">
              Save <Publish />
            </Button>
          </span>
        </form>
        <Divider style={{ margin: 16 }} />

        {isSuperAdmin ? <StoreDomainEdit item={store} actions={actions} /> : ''}

        <h4>Invites sent</h4>
        <StoreInvites invites={invites} />
        <StoreSellersList createInvite={createInvite} store={store} actions={actions} removePendingInvite={removePendingInvite} />
        <div sx={{ mt: 3 }}>
          <Typography component="p">Status: {store.status}</Typography>
          <Typography component="p">Payment mode: {store.paypalDeveloperMode} </Typography>
        </div>
      </Card>
    </>
  );
}
