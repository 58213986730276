import React from "react";
import { Link, Typography } from "@mui/material";
import { Link as RLink } from "react-router-dom";

let STORE_NAME = "-- --"

export function AppName({ store }) {
  if (store.name) {
    STORE_NAME = store.name;
  }

  return (
    <Typography className="app-bar-title" component="h2" variant="h5">
      <Link underline="none" component={RLink} color="inherit" to={"/"}>{STORE_NAME}</Link>
    </Typography>
  );
}
