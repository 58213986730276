import React from "react";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
import { BSON } from "realm-web";

const { dataSourceName } = Config();

export function useDeliveryOptions() {
  const realmApp = useRealmApp();
  const STORAGE_KEY = `${realmApp.currentUser.id}_deliveryoptions`;
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "deliveryoptions",
  });

  React.useEffect(() => {
    if (sessionStorage.getItem(STORAGE_KEY)) {
      setItems(JSON.parse(sessionStorage.getItem(STORAGE_KEY)));
      setLoading(false);
      return
    }
    collection.find().then((res) => {
      console.log('useDeliveryOptions.js fetch:mongo', {res})
      setItems(res);
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(res));
      setLoading(false);
    });
  }, [collection, STORAGE_KEY]);

  const addDeliveryOption = async ({name, value}) => {
    const item = {};
    item._id = new BSON.ObjectID()
    item._partition = realmApp.currentUser.id;
    // item.storeId = String(store._id);
    item.name = name;
    item.value = value;
    // item.updated_ts = new Date();
    try {
      await collection.insertOne(item);
      items.push(item);
      setItems(items);
      sessionStorage.removeItem(STORAGE_KEY);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const updateDeliveryOption = async (item) => {
    console.log('DeliveryOption:', item)
    try {
      await collection.updateOne(
        {_id: item._id},
        { $set: item }
      );
      sessionStorage.removeItem(STORAGE_KEY);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const deleteDeliveryOption = async (item) => {
    const updatedItems = items.filter((i) => i._id !== item._id);
    setItems(updatedItems);
    await collection.deleteOne({ _id: item._id });
  };

  return {
    loading,
    items,
    addDeliveryOption,
    updateDeliveryOption,
    deleteDeliveryOption
  };
}
