import React from "react";
import { BSON } from "realm-web";
import { Link as RLink, useParams } from "react-router-dom";
import { Button, ButtonGroup, Container, Divider, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useStores } from "./useStores";

export function ShopCategories({ currentUser }) {
    const { shopId } = useParams();
    const { loading, stores, updateStore } = useStores();
    const [isFormDirty, setFormDirty] = React.useState(false);
    const [shop, setShop] = React.useState(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    // function filterShop(stores) {
    //     return stores.find(s => BSON.ObjectID(s._id).toString() === shopId)
    // }

    function saveCategories(params) {
        console.log(shop.categories);
        updateStore(shop);
        setSnackbarOpen(true);
        setFormDirty(false);
    }

    function handleDiscountChange(e) {
        setFormDirty(true);
        console.log('change:', e.target.name, e.target.value);
        // const _shop = JSON.parse(JSON.stringify(shop)); // wasn't needed
        let cat = shop.categories.find(i => i.slug === e.target.name.split('_')[0]);
        if (Number(e.target.value) >= 0) {
            cat.discountRate = Number(e.target.value);
        }
        // setShop(shop); // wasn't needed.
    }

    React.useEffect(() => {
        if (!loading) {
            const shop = stores.find(s => BSON.ObjectID(s._id).toString() === shopId)
            setShop(shop)
        }
    }, [loading, stores, shopId])

    return (
        <Container disableGutters={true} maxWidth="sm" sx={{ pt: 2 }}>
            <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button component={RLink} to={`/shop/${shopId}/stats`}>KPIs</Button>
                <Button component={RLink} to={`/shop/${shopId}/events`}>
                    Order Events
                </Button>
                <Button variant={"contained"} component={RLink} to={`/shop/${shopId}/categories`}>
                    Categories
                </Button>
            </ButtonGroup>
            <Divider sx={{ my: 2 }} />
            <>{shop && shop.name}</>
            <TableContainer component={Paper}>
                <Table aria-label="listing table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" variant="head">Name</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Home?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shop && shop.categories.map((i) => (
                            <TableRow hover tabIndex={-1} key={i.slug}>
                                <TableCell>
                                    {i.name}
                                </TableCell>
                                <TableCell width={95}>
                                    <TextField
                                        id="input-discountRate"
                                        fullWidth={false}
                                        type="number"
                                        name={`${i.slug}_discountRate`}
                                        defaultValue={i.discountRate}
                                        onChange={handleDiscountChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    {`${i.home}`}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button disabled={!isFormDirty} sx={{ mt: 1 }} variant={"contained"} onClick={saveCategories}>Save Categories</Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpen(false)}
                message="Categories saved"
            />
        </Container>
    )


}