import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Link,
} from "@mui/material";
import { timeAgo } from "../../../hooks/util-hooks";
import { Link as RLink, useNavigate } from "react-router-dom";

export function ShopsTable({ items }) {
  const navigate = useNavigate()
  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="listing table">
        <TableHead>
          <TableRow>
            <TableCell align="left" variant="head">Name</TableCell>
            <TableCell>Domain</TableCell>
            <TableCell sortDirection={"desc"}>Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!items.length &&
            <TableRow>
              <TableCell>Nothing here... <Link component={RLink} to={'/shop/new'}>Create a store</Link></TableCell>
            </TableRow>}
          {items.map((i) => (
            <TableRow hover tabIndex={-1} key={i._id} 
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={() => {
              navigate(`/shop/${i._id}`)
            }}
            >
              <TableCell scope="row">
                <Link component={RLink} to={`/shop/${i._id}`}>
                  {i.name}
                </Link>
              </TableCell>
              <TableCell>
                {i.domains.join(', ')}
              </TableCell>
              <TableCell>{timeAgo(i.updated_ts)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
