import { Avatar, Grid, Typography, useTheme } from "@mui/material";

function ChatMessage({ side, messages, sender }) {
    const theme = useTheme();
    const avatarSize = { width: 28, height: 28 }
    // console.log({sender});

    function getChatStyle(side) {
        if (side === 'left') {
            return {
                wordBreak: "break-word",
                display: "inline-block",
                px: 2,
                my: .3,
                py: .6,
                borderRadius: '1em',
                borderBottomLeftRadius: '2px',
                backgroundColor: theme.palette.neutral.main,
                color: '#fff'
            }
        } else {
            return {
                wordBreak: "break-word",
                display: "inline-block",
                px: 1,
                my: .3,
                py: .5,
                borderRadius: '1em',
                borderBottomRightRadius: '2px',
                backgroundColor: theme.palette.secondary.main,
                color: '#000'
            }
        }

    }

    return (<>
        <Grid container spacing={1}
            justify={side === "right" ? "flex-end" : "flex-start"}
        >
            {side === "left" && (
                <Grid item xs={2}>
                    <Avatar alt={sender?.name} src={sender?.imageUrl} sx={avatarSize}>{sender?.name ? sender?.name[0]: '' }</Avatar>
                </Grid>
            )}
            <Grid item xs={10}>
                {messages.map((msg, i) => {
                    return (
                        <Typography align={side} key={i} component='div'>
                            <Typography align={side} sx={getChatStyle(side)}>
                                {msg.text}
                            </Typography>
                        </Typography>
                    );
                })}
            </Grid>
            {side === "right" && (
                <Grid item xs={2}>
                    <Avatar alt={sender?.name} src={sender?.imageUrl} sx={avatarSize}>{sender?.name ? sender?.name[0]: ''}</Avatar>
                </Grid>
            )}
        </Grid>
    </>);
}

export default ChatMessage;