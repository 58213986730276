import axios from "axios";
import Config from "./Config";
const { appId, endpointHost }  = Config();
const endpointUrl = `https://${endpointHost}/app/${appId}/endpoint`
const headers = { 
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

export async function getEndpoint(path, { queryParams }) {
    const config = { url: `${endpointUrl}/${path}`, headers };
    if (queryParams) {
        const searchParams = new URLSearchParams(queryParams);
        config.url = `${config.url}?${searchParams.toString()}`;
    }

    return axios(config)
        .then(response => {
          const { data } = response;
          console.log('getEndpoint: fetch', { data });
          return data.documents;
        });
}

export async function userEmailTaken(email) {
  const queryParams = {email}
  return getEndpoint('useremailtaken', {queryParams})
}

export async function getItem(itemId) {
  const queryParams = {itemId}
  return getEndpoint('getListing', {queryParams})
}

export async function SearchItems(text, storeId) {
  const queryParams = {text, storeId}
  return getEndpoint('search', { queryParams })
}

export async function getOnboardURL() {
  const config = { url: `${endpointUrl}/onboardurl`, headers };
  return axios(config)
        .then(response => {
          const { data } = response;
          console.log('getEndpoint: fetch', { data });
          return data;
        })
        .catch(err => {
            console.error('getEndpoint error:', { err });
        });
}

const STATUS_CACHE = {}
export async function getOnboardStatus(merchantId) {
  const config = { url: `${endpointUrl}/sellerstatus?merchantId=${merchantId}`, headers };
  if (STATUS_CACHE[merchantId]) {
    return Promise.resolve(STATUS_CACHE[merchantId]);
  }
  return axios(config)
        .then(response => {
          const { data } = response;
          STATUS_CACHE[merchantId] = data;
          console.log('getEndpoint: fetch', { data });
          return data;
        });
}