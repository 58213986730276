import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import React from "react";
import { getOnboardURL } from "../../../shared/getEndpoint";
import { CheckBox, CurrencyExchange, Error } from "@mui/icons-material";
import { useUsers } from "../../../hooks/useUsers";

export function MerchantAccountDialog({ open, setShowDialog, setFormMerchantId, sellerStatus, formMerchantId = false }) {
    const [openLoading, setOpenLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const { upsertUser } = useUsers();
    // casting response
    const {legal_name, payments_receivable, primary_email_confirmed} = sellerStatus;
    const legalName = typeof legal_name === 'string' ? legal_name : 'N/A';
    const paymentsReceivable = typeof payments_receivable === 'boolean' ? payments_receivable : false;
    const emailConfirmed = typeof primary_email_confirmed === 'boolean' ? primary_email_confirmed : false;

    const handleClose = (value) => {
        setShowDialog(false);
    };

    async function handleConnect(params) {
        setOpenLoading(true);
        setTimeout(() => {
            setOpenLoading(false);
        }, 6000)
        try {
            const { links } = await getOnboardURL();
            // window.open(links[1].href);
            window.location.assign(links[1].href);
        } catch (ex) {
            setErrorMsg("Error!")
            setOpenLoading(false);
        }
    };

    function handleDisconnect(e) {
        console.log('disconnect');
        upsertUser({ merchantId: '' })
        setFormMerchantId('')
        setShowDialog(false);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={false}>
            <DialogTitle>Merchant account</DialogTitle>
            <DialogContent>
                {formMerchantId ?
                    <>
                        <Typography variant="subtitle2">Account: {legalName} (Paypal) </Typography>
                        <br />
                        <Typography variant="p">Payments receivable? {paymentsReceivable ? <CheckBox color="success" sx={{verticalAlign:"middle"}} /> : <Error color="error" />}  </Typography>
                        <br />
                        <Typography variant="p">Email confirmed? {emailConfirmed ? <CheckBox color="success" sx={{verticalAlign:"middle"}} /> : <Error color="error" />}  </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="subtitle1" gutterBottom>Disconnecting your PayPal account will prevent you from offering PayPal services and products on your shop.</Typography>
                        <Typography sx={{ textAlign: 'center' }} variant="h6" gutterBottom>
                            Do you wish to continue?
                        </Typography>
                        <Typography sx={{ textAlign: 'center' }}>
                            <Button sx={{my:2}} variant="contained" color="error" edge="end" onClick={handleDisconnect}>
                               Yes, disconnect
                            </Button>
                            <Button variant="outlined" sx={{ m: 2 }} onClick={handleClose}>
                                Cancel
                            </Button>
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="subtitle2" style={{ margin: 1 }}>Disconnecting from your PayPal account</Typography>
                        <ul style={{ fontSize: 12 }}>
                            <li>
                                Login to your PayPal account and click on your icon in the top-right corner
                            </li>
                            <li>
                                Under &quot;Account Settings&quot; select &quot;API Access&quot;
                            </li>
                            <li>
                                Under REST API Integration, select &quot;Manage REST API apps and credentials&quot;
                            </li>
                            <li>
                                Select the app you would like to revoke and remove permissions from the platform
                            </li>
                        </ul>
                    </>
                    :
                    <Button variant="contained" color="info" onClick={handleConnect}
                        startIcon={<CurrencyExchange />}
                        endIcon={openLoading && <CircularProgress color="info" />}>
                        Continue to Paypal <Typography color={"error"}>&nbsp; {errorMsg}</Typography>
                    </Button>
                }
            </DialogContent>
        </Dialog>
    )
}