import React from "react";
import { CloseRounded } from "@mui/icons-material";
import {
    Avatar, Dialog, DialogContent, DialogTitle, Divider,
    IconButton, List, ListItem, ListItemAvatar, ListItemButton,
    ListItemText, Typography, useMediaQuery, useTheme
} from "@mui/material";
import Conversation from "./Conversation";
import { useRealmApp } from "../../components/RealmApp";

export default function ConversationList({ chats }) {
    const realmApp = useRealmApp();
    const [open, setOpen] = React.useState(false);
    const [chat, setChat] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogTitleStyle = { minWidth: 320 }
    if (!fullScreen) {
        dialogTitleStyle.minWidth = 520
    }

    function handleClose(value) {
        setOpen(false);
    }

    function getOtherUser(chat) {
        return chat.message.usersInfo.find(u => u.id !== realmApp.currentUser.id);
    }

    return (
        <>
            <List sx={{ width: '100%', maxWidth: 480, bgcolor: 'background.paper' }}>
                {chats.map((c, idx) => (
                    <span key={idx}>
                        <ListItem disablePadding={true} alignItems="flex-start">
                            <ListItemButton disabled={c.disabled}
                                onClick={() => {
                                    setOpen(true)
                                    setChat({ ...c })
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar alt={getOtherUser(c).name}>{getOtherUser(c).name ? getOtherUser(c).name[0] : ''}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ color: 'primary' }}
                                    primary={getOtherUser(c).name}
                                    secondary={
                                        <>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {c.message.sender === "you" && <>You: </>}
                                            </Typography>
                                            {c.message.text}
                                        </>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </span>
                ))}
            </List>
            {chat &&
                <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
                    <DialogTitle sx={dialogTitleStyle}>Chat with {getOtherUser(chat).name}
                        <IconButton style={{ float: 'right' }} onClick={() => setOpen(false)}>
                            <CloseRounded />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Conversation chat={chat} />
                    </DialogContent>
                </Dialog>
            }
        </>);
}