import React from "react";
import { Button, Card, CardHeader, Divider, LinearProgress } from "@mui/material";
import { AddComment } from "@mui/icons-material";
import ConversationList from "./ConversationList";
import { useChats } from "./useChats";

function ChatPage() {
    const { loading, chats } = useChats()

    if (loading) return <LinearProgress />
    return (
        <Card
            sx={{
                flexGrow: 1,
                p: 1,
                my: 2,
                mx: 'auto',
                maxWidth: 400
            }}>
            <CardHeader
                sx={{ px: .4 }}
                action={
                    <Button disabled={true} variant="contained" endIcon={<AddComment />}>
                        New
                    </Button>
                }
                title={`Chats`}
            // subheader="..."
            />
            <Divider sx={{ mb: 2 }} />
            {chats.length > 0 &&
                <ConversationList chats={chats} />
            }
        </Card>
    );
}

export default ChatPage;