import React from "react";
import { Container, CssBaseline, LinearProgress } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Footer } from "./controls/Footer";
import { HomePage } from "./pages/HomePage";
import { RealmAppProvider, useRealmApp } from "../components/RealmApp";
import { ThemeProvider } from "../components/Theme";
import Config from "../shared/Config";
import "./App.css";
import AuthRedirect from "./pages/AuthRedirect";
import { Login } from "./pages/Login";
import { ProfilePage } from "./pages/Profile/ProfilePage";
import { RedirectPage } from "./pages/RedirectPage";
import { AppTopBar } from "./controls/AppTopBar";
import { LoginDialog } from "./controls/LoginDialog";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { EmailConfirmPage } from "./pages/EmailConfirmPage";
import AppDrawer from "./controls/AppDrawer";
import Export from "./pages/Export";
import { ShopsPage } from "./pages/Shops/ShopsPage";
import { About } from "./pages/About/About";
import Conversation from "../modules/Chat/Conversation";
import ChatPage from "../modules/Chat/Chat";
import { ShopEditPage } from "./pages/Shops/ShopEditPage";
import { ShopCreatePage } from "./pages/Shops/ShopCreatePage";
import { Register } from "./pages/Register";
import { PrivacyPage } from "./pages/About/Privacy";
import { TermsPage } from "./pages/About/Terms";
import { PaypalOnboardPage } from "./pages/PaypalOnboardPage";
import { ShopStats } from "./pages/Shops/ShopStats";
import { ShopEvents } from "./pages/Shops/ShopEvents";
import { ShopCategories } from "./pages/Shops/ShopCategories";

const { appId } = Config();

function RequireAuth({ children }) {
  const { currentUser } = useRealmApp();
  const location = useLocation();
  if (currentUser?.isLoggedIn === true) {
    return children
  } else {
    return <Navigate to={`/login?redirect=${location.pathname}`} replace />;
  }
}

export default function AppWithRealm() {
  return (
    <RealmAppProvider appId={appId}>
      <ThemeProvider>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </RealmAppProvider>
  );
}

function App() {
  const { currentUser, logOut } = useRealmApp();
  const [showLogin, setShowLogin] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const loading = false;
  const store = { name: 'SwagShops', theme: '' };

  return (
    <div sx={{ bgcolor: 'background.default' }}>
      <Router>
        <AppTopBar
          setDrawerOpen={setDrawerOpen}
          store={store}
          currentUser={currentUser}
          setShowLogin={setShowLogin} />
        <AppDrawer
          store={store}
          currentUser={currentUser}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
          setShowLogin={setShowLogin}
          logOut={logOut} />
        {loading ? <LinearProgress /> :
          <Container disableGutters={true}>
            <LoginDialog open={showLogin} setShowLogin={setShowLogin} />
            <Routes>
              <Route path="/" element={<HomePage currentUser={currentUser} />} />
              <Route path="/about" element={<About />} />
              <Route path="/privacy-terms" element={<PrivacyPage />} />
              <Route path="/about/privacy" element={<PrivacyPage />} />
              <Route path="/about/terms" element={<TermsPage />} />
              <Route path="/redirect" element={<RedirectPage />} />
              <Route path="/login" element={<Login store={store} />} />
              <Route path="/seller-signup" element={<Register setShowLogin={setShowLogin} />} />
              <Route path="/signup" element={<Login isSignup={true} store={store} />} />
              <Route path="/auth/callback" element={<AuthRedirect />} />
              <Route path="/resetPassword" element={<ResetPasswordPage />} />
              <Route path="/confirm" element={<EmailConfirmPage />} />
              <Route path="/profile" element={<RequireAuth><ProfilePage store={store} currentUser={currentUser} /></RequireAuth>} />
              <Route path="/shops" element={<RequireAuth><ShopsPage currentUser={currentUser} /></RequireAuth>} />
              <Route path="/shop/:shopId" element={<RequireAuth><ShopEditPage currentUser={currentUser} /></RequireAuth>} />
              <Route path="/shop/:shopId/stats" element={<RequireAuth><ShopStats currentUser={currentUser} /></RequireAuth>} />
              <Route path="/shop/new" element={<RequireAuth><ShopCreatePage currentUser={currentUser} /></RequireAuth>} />
              <Route path="/shop/:shopId/events" element={<RequireAuth><ShopEvents currentUser={currentUser} /></RequireAuth>} />
              <Route path="/shop/:shopId/categories" element={<RequireAuth><ShopCategories currentUser={currentUser} /></RequireAuth>} />
              <Route path="/chat" element={<RequireAuth><ChatPage currentUser={currentUser} /></RequireAuth>} />
              <Route path="/chat/c/:conversationId" element={<RequireAuth><Conversation currentUser={currentUser} /></RequireAuth>} />
              <Route path="/exports" element={<RequireAuth><Export currentUser={currentUser} /></RequireAuth>} />
              <Route path="/onboardcomplete" element={<PaypalOnboardPage />} />
            </Routes>
            <Footer />
          </Container>}
      </Router>
    </div>
  );
}
