import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { timeAgo } from "../../../hooks/util-hooks";

export function StoreInvites({invites}) {
    
    return (
        <Table sx={{ minWidth: 350 }} size="small" aria-label="stores table">
        <TableHead>
          <TableRow>
            <TableCell>Sent</TableCell>
            <TableCell>Invite</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {invites.map((i) => (
          <TableRow key={i._id}
             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>{timeAgo(i.createdTs)}</TableCell>
            <TableCell>{i.email}, {i.kind} on {i.store.name}</TableCell>
            <TableCell style={{textTransform:'capitalize'}}> {i.status} </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
    )
}