import realmJson from "../realm.json";
const { REACT_APP_BACKEND } = process.env; 

export default function Config() {
    let config = realmJson.production;
    if (REACT_APP_BACKEND === 'dev') {
        config = realmJson.development;
    }
    // console.log('Config:', config, process.env)
    return config 
}