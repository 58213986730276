import React from "react";
import {
  Container,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
} from "@mui/material";
import { useShowLoader } from "../../../hooks/util-hooks";
import useAxios from "axios-hooks";
import { EventsTable } from "../../lists/EventsTable";
import { isTokenExpired } from "../../../shared/helpers";
import Config from "../../../shared/Config";
import { useParams } from "react-router";
import { Search } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
const { appId, endpointHost } = Config();
const endpointUrl = `https://${endpointHost}/app/${appId}/endpoint`;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export function ShopEvents({ currentUser }) {
  // const { loading, stores } = useStores();
  const expiredToken = isTokenExpired(currentUser?.accessToken);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = React.useRef();
  const { shopId } = useParams();
  const [text, setText] = React.useState("ORDER.CREATED");
  headers.Authorization = `Bearer ${currentUser.accessToken}`;
  const config = {
    url: `${endpointUrl}/shop/events?text=${text}&storeId=${shopId}`,
    headers,
  };

  const [{ data, loading, error }, refetch] = useAxios(config);
  const showLoader = useShowLoader(loading, 200);

  React.useMemo(async () => {
    // console.log('useMemo()');
    if (expiredToken) {
      await currentUser.refreshAccessToken();
      refetch();
    }

    if(searchParams.get('q')) {
      setText(searchParams.get('q'));
      console.log('useMemo:', searchParams.get('q'));
    }

  }, [expiredToken, currentUser, refetch, searchParams]);

  async function handleSearch(e) {
    setSearchParams({ q: searchText.current.value });
    console.log("handleSearch text:", searchText.current.value);
  }

  async function handleEnter(e) {
    if (e.keyCode === 13) {
      console.log("handleEnter text:", searchText.current.value);
      setSearchParams({ q: searchText.current.value });
    }
  }

  return (
    <Container disableGutters={true} maxWidth="sm">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : (
          <div />
        )
      ) : (
        <>
          <Paper sx={{ textAlign: 'center', m: 2, maxWidth: 260 }}>
            <InputBase
              inputRef={searchText}
              onKeyDown={handleEnter}
              defaultValue={text}
              name="searchText"
              sx={{ ml: 1, flex: 1 }}
              placeholder="search orders..."
              inputProps={{ 'aria-label': 'search orders...' }}
            />
            <IconButton
              onClick={handleSearch}
              type="button"
              size={'large'}
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
          {error ? <>Error: {error.message}. Refresh page</> : ""}
          <EventsTable items={data.documents} currentUser={currentUser} />
        </>
      )}
    </Container>
  );
}
