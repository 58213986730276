import React from "react";
import {
    Button, Container, Divider, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import Config from "../../../shared/Config";
import useAxios from "axios-hooks";
import { Link, useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
const { appId, endpointHost } = Config();
const endpointUrl = `https://${endpointHost}/app/${appId}/endpoint`;
const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

export function ShopStats({ currentUser }) {
    const { shopId } = useParams();
    headers.Authorization = `Bearer ${currentUser.accessToken}`;
    const config = { url: `${endpointUrl}/shopstats`, headers };
    const [{ data, loading, error }] = useAxios(config);

    if (error) {
        currentUser.refreshAccessToken();
    }

    function handleChange(e) {
        console.log(shopId, e);
    }


    return (
        <Container disableGutters={true} maxWidth="sm">
       
            <Divider sx={{my:1}}></Divider>
            <Button component={Link} to={`/shop/${shopId}`} startIcon={<ArrowBack />}>Back</Button>
            <Paper sx={{ m: 1 }}>
                {error ? <>Error: {error.message}. Refresh page</> : ""}
                {(!loading && !error) ?
                    <TableContainer>
                        <Typography variant="h6">Weekly Revenue</Typography>
                        <BarChart
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: data.chartData.map(i => `${i.x.month}-${i.x.date}` ),
                                    scaleType: 'band',
                                },
                            ]}
                            series={[
                                {
                                    data: data.chartData.map(i => i.y), label:'$'
                                },
                            ]}
                            height={300}
                        />

                        <Typography variant="h6">Monthly Consignor Payout Report</Typography>
                        <InputLabel id="month-select-label">Select Month:</InputLabel>
                        <Select
                            labelId="month-select-label"
                            size="small"
                            id="month-select"
                            value={"2023-11"}
                            label="Month"
                            onChange={handleChange}
                        >
                            <MenuItem value={"2023-11"}>2023-11</MenuItem>
                        </Select>
                        <Table sx={{ minWidth: 310 }} size="small" aria-label="listing table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Consignor</TableCell>
                                    <TableCell>40% Due</TableCell>
                                    <TableCell>Sales Total</TableCell>
                                    <TableCell>Sales</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.chartData.map((i, idx) => (
                                    <TableRow hover tabIndex={-1} key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{i.group_series_0}</TableCell>
                                        <TableCell>${i.value ? i.value.toFixed(2) : 0} </TableCell>
                                        <TableCell>${i.value_series_1 ? i.value_series_1.toFixed(0) : 0}</TableCell>
                                        <TableCell>{i.value_series_0} ({i.group?.year + "-" + i.group?.month})</TableCell>
                                    </TableRow>))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : ""}
            </Paper>
        </Container>)
}
